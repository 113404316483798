<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-overlay
      v-if="loading"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      xs12
      multi-line
      :timeout="timeout"
      top
      color="orange"
    >
      {{ alertmessage }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-row class="d-flex justify-center mt-0 pb-16">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card
          class="mt-0"
        >
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <div class="primary--text text-center text-h1 mt-2 mb-4">
                    {{ $t('profile.profileDetails') }}
                  </div>
                </v-col>
              </v-row>
              <v-card
                elevation="0"
              >
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="First Name"
                      :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                    >
                      <v-text-field
                        v-model="firstname"
                        :error-messages="errors"
                        :label="$t('profile.firstName')"
                        outlined
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                    >
                      <v-text-field
                        v-model="lastname"
                        :label="$t('profile.lastName')"
                        :error-messages="errors"
                        outlined
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        outlined
                        disabled
                        :label="$t('profile.email')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone"
                      rules="required"
                    >
                      <v-text-field
                        v-model="mobile"
                        v-mask="'###-###-####'"
                        :error-messages="errors"
                        outlined
                        :label="$t('profile.phoneNumber')"
                        :hint="$t('profile.phoneConsentText')"
                        persistent-hint
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <span class="text-h3 ml-4 primary--text">{{ $t("profile.birthDate") }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Day"
                      rules="required"
                    >
                      <v-select
                        v-model="bdays"
                        :items="daysavailable"
                        :label="$t('profile.day')"
                        outlined
                        color="primary"
                        :error-messages="errors"
                        @change="calcbday"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Month"
                      rules="required"
                    >
                      <v-select
                        v-model="bmonths"
                        :items="monthsavailable"
                        :label="$t('profile.month')"
                        outlined
                        color="primary"
                        :error-messages="errors"
                        @change="calcbday"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Year"
                      rules="required"
                    >
                      <v-select
                        v-model="byears"
                        :items="yearsavailable"
                        :label="$t('profile.year')"
                        outlined
                        color="primary"
                        :error-messages="errors"
                        @change="calcbday"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="text-h3 ml-4 primary--text">
                      {{ $t("profile.physical") }}
                    </div>
                    <v-radio-group
                      v-model="units"
                      row
                      mandatory
                    >
                      <v-radio
                        :label="$t('profile.measurementSystem.standard')"
                        value="standard"
                        class="custom-radio"
                      ></v-radio>
                      <v-radio
                        :label="$t('profile.measurementSystem.metric')"
                        value="metric"
                        class="custom-radio"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Height (Feet)"
                      rules="required"
                    >
                      <v-select
                        v-model="heightfeet"
                        :items="feetrange"
                        :label="$t('profile.heightFeet')"
                        outlined
                        color="primary"
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Height (inches)"
                      rules="required"
                    >
                      <v-select
                        v-model="heightinches"
                        :items="inchesrange"
                        :label="$t('profile.heightInches')"
                        outlined
                        color="primary"
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    sm="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Weight"
                      rules="required|max_value:400"
                    >
                      <v-text-field
                        v-model="weightpounds"
                        v-mask="'###'"
                        :label="$t('profile.weightPounds')"
                        outlined
                        color="primary"
                        :error-messages="errors"
                        @change="calcbday"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <span class="text-h3 ml-4 primary--text">{{ $t("profile.gender") }}</span>
                    <v-radio-group
                      v-model="gender"
                      row
                      mandatory
                    >
                      <v-radio
                        :label="$t('profile.genderOptions.male')"
                        value="Male"
                        class="custom-radio"
                      ></v-radio>
                      <v-radio
                        :label="$t('profile.genderOptions.female')"
                        value="Female"
                        class="custom-radio"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="text-h3 ml-4 primary--text">{{ $t("profile.location") }}</span>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="address"
                        :label="$t('profile.address')"
                        outlined
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="mailingSuite"
                      outlined
                      :label="$t('profile.additionalAddressInfo')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"

                    lg="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="city"
                        :label="$t('profile.city')"
                        outlined
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="state"
                        :label="(country === 'Canada')?$t('profile.province'):$t('profile.state')"
                        :items="StateList"
                        outlined
                        :error-messages="errors"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Zip"
                      rules="required|min:5|max:8"
                    >
                      <v-text-field
                        v-model="zipcode"
                        :label="$t('profile.zip')"
                        outlined
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Country"
                      rules="required"
                    >
                      <v-select
                        v-model="country"
                        :items="supportedCountries"
                        :label="$t('profile.country')"
                        outlined
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="invalid"
                    color="primary"
                    @click="updateuser"
                  >
                    {{ $t("common.buttons.save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import moment from 'moment'
  var states = require('ustates')
  export default {
    name: 'Userprofile',
    data () {
      return {
        snackbar: '',
        units: '',
        gender: '',
        timeout: 5000,
        date: null,
        menu: false,
        pickerDate: '',
        bdays: '',
        bmonths: '',
        byears: '',
        alertmessage: '',
        email: '',
        firstname: '',
        lastname: '',
        mobile: '',
        weightpounds: '',
        weightkilos: '',
        heightfeet: 1,
        heightinches: 1,
        heightcm: 1,
        address: '',
        mailingSuite: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
        provinces: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Saskatchewan']
      }
    },
    computed: {
      lang () {
        return this.$store.getters.getLanguage
      },
      getCurrentLang () {
        return this?.currentuser?.preferredLang
      },
      StateList () {
        let statelist = Object.values(states)
        statelist = statelist.slice(0, 51)
        let list = statelist.map(entry => entry.name)
        if (this.country === 'Canada') {
          list = this.provinces
        }
        console.log(list)
        return list
      },
      inchesrange () {
        let ranges = []
        let min = 0
        let max = 11
        for (let i = min; i <= max; i++) {
          ranges.push(i)
        }
        return ranges
      },
      feetrange () {
        let ranges = []
        let min = 3
        let max = 8
        for (let i = min; i <= max; i++) {
          ranges.push(i)
        }
        return ranges
      },
      supportedCountries () {
        return ['United States', 'Canada']
      },
      calcbday () {
        console.log('CALC B DAY ')
        let dateofbirth = '-'
        console.log(this.byears + '-' + this.bmonths + '-' + this.bdays)
        if (this.byears && this.bmonths && this.bdays) {
          dateofbirth = moment(this.byears + '-' + this.bmonths + '-' + this.bdays, 'YYYY-MM-DD').toDate()
          let now = moment().toDate()
          this.primaryTravellerAge = moment().diff(dateofbirth, 'years')
        } else {
          dateofbirth = '-'
        }
        return dateofbirth
      },
      yearsavailable () {
        let maxyear = 2021
        let startingyear = maxyear - 70
        let years = []
        for (let i = startingyear; i < maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      loading () {
        return this.$store.getters.getloading
      }
    },
    watch: {
      loading (val) {
        if (!val) {
          this.alertmessage = this.$t('profile.updateSuccess')
          this.snackbar = true
        }
      }
    },
    created () {
      this.heightcm = 1
      this.currentuser = this.$store.getters.getuser
      this.setdetails(this.$store.getters.getuser)
      this.email = this.$store.getters.user.email
      this.$gtag.pageview('UserProfile')
    },
    methods: {
      setdetails (data) {
        this.firstname = data.firstname
        this.lastname = data.lastname
        this.gender = data.gender
        this.mobile = data.mobile
        // this.email = data.email
        this.bdays = data.bdays
        this.bmonths = data.bmonths
        this.byears = data.byears
        this.address = data.address
        this.city = data.city
        this.state = data.state
        this.zipcode = data.zipcode
        this.country = data.country
        this.weightpounds = data.weightpounds
        this.heightfeet = data.heightfeet
        this.heightinches = data.heightinches
        this.preferredLang = data.preferredLang
        if (data.heightcm) {
          this.heightcm = data.heightcm
        } else {
          this.heightcm = 1
        }
      },
      getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData
        console.log('google address ', this.address)
        this.zipcode = addressData.postal_code
        this.city = addressData.locality
        this.address = addressData.name
        this.country = addressData.country
      },

      updateuser () {
        this.$gtag.event('UserProfileUpdated', { method: 'Updated', campaign: 'none' })
        let rec = {
          units: this.units,
          gender: this.gender,
          bdays: this.bdays,
          bmonths: this.bmonths,
          byears: this.byears,
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          mobile: this.mobile,
          weightpounds: this.weightpounds,
          weightkilos: this.weightkilos,
          heightfeet: this.heightfeet,
          heightinches: this.heightinches,
          heightcm: this.heightcm,
          address: this.address,
          mailingSuite: this.mailingSuite,
          city: this.city,
          state: this.state,
          country: this.country,
          zipcode: this.zipcode,
        }
        this.$store.dispatch('updateUserProfile', rec)
        this.$store.dispatch('updatelang', { languageCode: this.lang, updateProfile: true })
      }
    },
  }
</script>

<style>
.custom-radio .v-label {
  margin-top: 10px;
}
</style>
